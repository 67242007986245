import { IconProps } from '@coldpbc/interfaces';

export const ColdCalculatorIcon = (props: IconProps) => {
	return (
		<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M7.25 5.75V18.75H17.8654V5.75H7.25ZM7.19234 4.5C6.53383 4.5 6 5.03383 6 5.69234V18.8077C6 19.4662 6.53383 20 7.19234 20H17.923C18.5816 20 19.1154 19.4662 19.1154 18.8077V5.69234C19.1154 5.03383 18.5816 4.5 17.923 4.5H7.19234Z"
				fill={props.color || 'white'}
			/>
			<path
				d="M9.5769 8.67318C9.5769 8.34393 9.84381 8.07703 10.1731 8.07703H14.9423C15.2715 8.07703 15.5384 8.34393 15.5384 8.67318C15.5384 9.00243 15.2715 9.26933 14.9423 9.26933H10.1731C9.84381 9.26933 9.5769 9.00243 9.5769 8.67318Z"
				fill={props.color || 'white'}
			/>
			<path
				d="M9.5769 8.67318C9.5769 8.34393 9.84381 8.07703 10.1731 8.07703H14.9423C15.2715 8.07703 15.5384 8.34393 15.5384 8.67318C15.5384 9.00243 15.2715 9.26933 14.9423 9.26933H10.1731C9.84381 9.26933 9.5769 9.00243 9.5769 8.67318Z"
				fill={props.color || 'white'}
			/>
			<path
				d="M9.5769 11.356C9.5769 10.8621 9.97727 10.4617 10.4711 10.4617C10.965 10.4617 11.3654 10.8621 11.3654 11.356C11.3654 11.8498 10.965 12.2502 10.4711 12.2502C9.97727 12.2502 9.5769 11.8498 9.5769 11.356Z"
				fill={props.color || 'white'}
			/>
			<path
				d="M9.5769 11.356C9.5769 10.8621 9.97727 10.4617 10.4711 10.4617C10.965 10.4617 11.3654 10.8621 11.3654 11.356C11.3654 11.8498 10.965 12.2502 10.4711 12.2502C9.97727 12.2502 9.5769 11.8498 9.5769 11.356Z"
				fill={props.color || 'white'}
			/>
			<path
				d="M11.6633 11.356C11.6633 10.8621 12.0637 10.4617 12.5576 10.4617C13.0514 10.4617 13.4518 10.8621 13.4518 11.356C13.4518 11.8498 13.0514 12.2502 12.5576 12.2502C12.0637 12.2502 11.6633 11.8498 11.6633 11.356Z"
				fill={props.color || 'white'}
			/>
			<path
				d="M11.6633 11.356C11.6633 10.8621 12.0637 10.4617 12.5576 10.4617C13.0514 10.4617 13.4518 10.8621 13.4518 11.356C13.4518 11.8498 13.0514 12.2502 12.5576 12.2502C12.0637 12.2502 11.6633 11.8498 11.6633 11.356Z"
				fill={props.color || 'white'}
			/>
			<path
				d="M13.75 11.356C13.75 10.8621 14.1504 10.4617 14.6442 10.4617C15.1381 10.4617 15.5385 10.8621 15.5385 11.356C15.5385 11.8498 15.1381 12.2502 14.6442 12.2502C14.1504 12.2502 13.75 11.8498 13.75 11.356Z"
				fill={props.color || 'white'}
			/>
			<path
				d="M13.75 11.356C13.75 10.8621 14.1504 10.4617 14.6442 10.4617C15.1381 10.4617 15.5385 10.8621 15.5385 11.356C15.5385 11.8498 15.1381 12.2502 14.6442 12.2502C14.1504 12.2502 13.75 11.8498 13.75 11.356Z"
				fill={props.color || 'white'}
			/>
			<path
				d="M9.5769 13.4423C9.5769 12.9484 9.97727 12.548 10.4711 12.548C10.965 12.548 11.3654 12.9484 11.3654 13.4423C11.3654 13.9361 10.965 14.3365 10.4711 14.3365C9.97727 14.3365 9.5769 13.9361 9.5769 13.4423Z"
				fill={props.color || 'white'}
			/>
			<path
				d="M9.5769 13.4423C9.5769 12.9484 9.97727 12.548 10.4711 12.548C10.965 12.548 11.3654 12.9484 11.3654 13.4423C11.3654 13.9361 10.965 14.3365 10.4711 14.3365C9.97727 14.3365 9.5769 13.9361 9.5769 13.4423Z"
				fill={props.color || 'white'}
			/>
			<path
				d="M11.6633 13.4424C11.6633 12.9485 12.0637 12.5482 12.5576 12.5482C13.0514 12.5482 13.4518 12.9485 13.4518 13.4424C13.4518 13.9363 13.0514 14.3366 12.5576 14.3366C12.0637 14.3366 11.6633 13.9363 11.6633 13.4424Z"
				fill={props.color || 'white'}
			/>
			<path
				d="M11.6633 13.4424C11.6633 12.9485 12.0637 12.5482 12.5576 12.5482C13.0514 12.5482 13.4518 12.9485 13.4518 13.4424C13.4518 13.9363 13.0514 14.3366 12.5576 14.3366C12.0637 14.3366 11.6633 13.9363 11.6633 13.4424Z"
				fill={props.color || 'white'}
			/>
			<path
				d="M13.75 13.4424C13.75 12.9485 14.1504 12.5482 14.6442 12.5482C15.1381 12.5482 15.5385 12.9485 15.5385 13.4424C15.5385 13.9363 15.1381 14.3366 14.6442 14.3366C14.1504 14.3366 13.75 13.9363 13.75 13.4424Z"
				fill={props.color || 'white'}
			/>
			<path
				d="M13.75 13.4424C13.75 12.9485 14.1504 12.5482 14.6442 12.5482C15.1381 12.5482 15.5385 12.9485 15.5385 13.4424C15.5385 13.9363 15.1381 14.3366 14.6442 14.3366C14.1504 14.3366 13.75 13.9363 13.75 13.4424Z"
				fill={props.color || 'white'}
			/>
			<path
				d="M9.5769 15.5291C9.5769 15.0352 9.97727 14.6348 10.4711 14.6348C10.965 14.6348 11.3654 15.0352 11.3654 15.5291C11.3654 16.0229 10.965 16.4233 10.4711 16.4233C9.97727 16.4233 9.5769 16.0229 9.5769 15.5291Z"
				fill={props.color || 'white'}
			/>
			<path
				d="M9.5769 15.5291C9.5769 15.0352 9.97727 14.6348 10.4711 14.6348C10.965 14.6348 11.3654 15.0352 11.3654 15.5291C11.3654 16.0229 10.965 16.4233 10.4711 16.4233C9.97727 16.4233 9.5769 16.0229 9.5769 15.5291Z"
				fill={props.color || 'white'}
			/>
			<path
				d="M11.6633 15.5289C11.6633 15.0351 12.0637 14.6347 12.5576 14.6347C13.0514 14.6347 13.4518 15.0351 13.4518 15.5289C13.4518 16.0228 13.0514 16.4232 12.5576 16.4232C12.0637 16.4232 11.6633 16.0228 11.6633 15.5289Z"
				fill={props.color || 'white'}
			/>
			<path
				d="M11.6633 15.5289C11.6633 15.0351 12.0637 14.6347 12.5576 14.6347C13.0514 14.6347 13.4518 15.0351 13.4518 15.5289C13.4518 16.0228 13.0514 16.4232 12.5576 16.4232C12.0637 16.4232 11.6633 16.0228 11.6633 15.5289Z"
				fill={props.color || 'white'}
			/>
			<path
				d="M13.75 15.5289C13.75 15.0351 14.1504 14.6347 14.6442 14.6347C15.1381 14.6347 15.5385 15.0351 15.5385 15.5289C15.5385 16.0228 15.1381 16.4232 14.6442 16.4232C14.1504 16.4232 13.75 16.0228 13.75 15.5289Z"
				fill={props.color || 'white'}
			/>
			<path
				d="M13.75 15.5289C13.75 15.0351 14.1504 14.6347 14.6442 14.6347C15.1381 14.6347 15.5385 15.0351 15.5385 15.5289C15.5385 16.0228 15.1381 16.4232 14.6442 16.4232C14.1504 16.4232 13.75 16.0228 13.75 15.5289Z"
				fill={props.color || 'white'}
			/>
		</svg>
	);
};
