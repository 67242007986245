import React, { useState, useRef, useEffect } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../../application';
import {
  AttributeAssuranceCountGraph,
  AttributeAssuranceEntityDetail,
  AttributeAssuranceSingleStatus, AttributeAssuranceStatusGraph,
  SustainabilityCardExpandedView,
} from '@coldpbc/components';
import { SustainabilityAttribute } from '@coldpbc/interfaces';
import { EntityLevel } from '@coldpbc/enums';

interface SustainabilityAttributeCardProps {
  sustainabilityAttribute: SustainabilityAttribute;
  cardStyle?: SustainabilityAttributeCardStyle;
  displayedOnEntityLevel?: EntityLevel;
}

export enum SustainabilityAttributeCardStyle {
  ENTITY_DETAIL = 'ENTITY DETAIL',
  GRAPH = 'GRAPH',
  SINGLE_STATUS = 'SINGLE STATUS',
}

export const DEFAULT_ICON_URL = 'https://cold-public-assets.s3.us-east-2.amazonaws.com/3rdPartyLogos/sustainability_attributes/NoImage.png';

const _SustainabilityAttributeCard: React.FC<SustainabilityAttributeCardProps> = ({ sustainabilityAttribute, cardStyle, displayedOnEntityLevel }) => {

  // If we don't get a logo image from the backend, we'll use the default
	const [imgSrc, setImgSrc] = useState<string>(sustainabilityAttribute.logoUrl || DEFAULT_ICON_URL);
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const expandedContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isExpanded && expandedContentRef.current) {
      // Wait a frame for the DataGrid to render
      requestAnimationFrame(() => {
        expandedContentRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      });
    }
  }, [isExpanded]);

  const renderContent = () => {
    switch (cardStyle) {
      case SustainabilityAttributeCardStyle.ENTITY_DETAIL:
        return (
          <AttributeAssuranceEntityDetail
            sustainabilityAttribute={sustainabilityAttribute}
            displayedOnEntityLevel={displayedOnEntityLevel || EntityLevel.PRODUCT}
            expanded={isExpanded}
            onClick={() => setExpanded(!isExpanded)}
          />
        );
      case SustainabilityAttributeCardStyle.SINGLE_STATUS:
        return (
          <AttributeAssuranceSingleStatus sustainabilityAttribute={sustainabilityAttribute} />
        );
      case SustainabilityAttributeCardStyle.GRAPH:
      default:
        return (
          <AttributeAssuranceCountGraph sustainabilityAttribute={sustainabilityAttribute} />
        );
    }
  };

	return (
		<div>
      <div className={`w-full h-auto p-4 ${isExpanded ? 'rounded-t-2xl' : 'rounded-2xl'} border border-gray-90 flex flex-col`}>
        <div className="flex">
          <div className="w-24 h-24 flex-shrink-0 mr-4">
            <img className="w-full h-full object-cover rounded-lg" src={imgSrc} alt={`Logo for ${sustainabilityAttribute.name}`} onError={() => setImgSrc(DEFAULT_ICON_URL)} />
          </div>
          <div className="flex-grow flex flex-col justify-between min-w-0 overflow-hidden">
            <div className="w-full overflow-hidden">
              <div className="text-tc-primary text-l font-bold truncate" title={sustainabilityAttribute.name}>
                {sustainabilityAttribute.name}
              </div>
            </div>
            {renderContent()}
          </div>
        </div>
      </div>
      {cardStyle === SustainabilityAttributeCardStyle.ENTITY_DETAIL && isExpanded && (
        <div ref={expandedContentRef}>
          <SustainabilityCardExpandedView
            sustainabilityAttribute={sustainabilityAttribute}
            displayedOnEntityLevel={displayedOnEntityLevel}
          />
        </div>
      )}
    </div>
	);
};

export const SustainabilityAttributeCard = withErrorBoundary(_SustainabilityAttributeCard, {
	FallbackComponent: props => <ErrorFallback {...props} />,
	onError: (error, info) => {
		console.error('Error occurred in SustainabilityAttributeCard: ', error);
	},
});
