export enum MaterialClassificationCategory {
  COATINGS_AND_LAMINATIONS = 'Coatings and Laminations',
  FOAM = 'Foam',
  INSULATION_MATERIAL = 'Insulation Material',
  LEATHER = 'Leather',
  LEATHER_ALTERNATIVES = 'Leather Alternatives',
  METALS = 'Metals',
  PLASTICS = 'Plastics',
  RUBBERS_ELASTOMERS = 'Rubbers/Elastomers',
  SYNTHETIC_LEATHER = 'Synthetic Leather',
  TEXTILES = 'Textiles',
  WOOD_BASED_MATERIALS = 'Wood-based Materials',
}
